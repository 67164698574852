import React, { memo, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import zxcvbn from 'zxcvbn';
import classNames from 'classnames';
import Button from '../../../../shared/components/Button/Button';
import Input from '../../../../shared/components/Input/Input';
import InputField from '../../../../shared/components/InputField/InputField';
import PasswordStrengthMeter from '../../../../shared/components/PasswordStrengthMeter/PasswordStrengthMeter';

import { registerUser } from 'redux/features/user';
import { RootState } from 'redux/store';
import { setErrorMessage } from 'pages/RegisterUser/redux';
import cookie from 'js-cookie';
import size from 'lodash/size';
import styles from './RegisterForm.module.scss';
import { useTranslation } from 'react-i18next';

// interface Props {
//     inviteToken: string;
//     email: string;
// }
interface RegisterFormProps {
    currentWizardStep: number;
    onWizardChange: (value: number) => void;
}

const RegisterFormComponent: React.FC<RegisterFormProps> = ({ currentWizardStep, onWizardChange }) => {
    const { t } = useTranslation();

    const initialFormValues = {
        information: {
            first_name: '',
            last_name: '',
        },
        password: '',
        password_again: '',
    };

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [passwordToValidate, setpasswordToValidate] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [showPass, setShowPass] = useState(!!cookie.get('provider') && cookie.get('provider') === 'xelacore' || true);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isNextStepDisabled, setIsNextStepDisabled] = useState(true);

    const dispatch = useDispatch();
    const errorMessage = useSelector<RootState, string | undefined>((state) => state.registerUserPage.errorMessage);


    useEffect(() => {
        if (firstName.trim() === '' || lastName.trim() === '') {
            setIsNextStepDisabled(true);
        } else {
            setIsNextStepDisabled(false);
        }
        onWizardChange(currentWizardStep)
    }, [firstName, lastName, onWizardChange, currentWizardStep]);


    const onFormValueSubmit = useCallback((formValue) => {
        if (!size(formValue.information.first_name) || !size(formValue.information.last_name)) {
            dispatch(setErrorMessage('First and last name are mandatory fields'));
            return;
        }

        const registerUserEntity = { ...formValue };
        delete registerUserEntity.password_again;

        if (!showPass) {
            delete registerUserEntity.password;
        }

        dispatch(registerUser({ registerUserEntity }));
    }, []);

    const checkTermsAccepted = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTermsAccepted(e.target.checked)
    }

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;

        if (name === 'information.first_name') {
            setFirstName(value ?? '')
        }
        if (name === 'information.last_name') {
            setLastName(value ?? '')
        }
        if (name === 'password') {
            setpasswordToValidate(value);
        }
        if (name === 'password_again') {
            setconfirmPassword(value);
        }
    }

    return (
        <Formik enableReinitialize
            initialValues={initialFormValues}
            onSubmit={(values) => onFormValueSubmit(values)}>
            {() => (
                <Form className={styles.root} onChange={(e) => handleFormChange(e)}>
                    {currentWizardStep === 1 && (
                        <div>
                            <InputField fieldName={t('register.first_name')} newStyle={false}>
                                <Input name='information.first_name' />
                            </InputField>
                            <InputField fieldName={t('register.last_name')} newStyle={false}>
                                <Input type='text' name='information.last_name' />
                            </InputField>
                            {showPass && (
                                <>
                                    <InputField fieldName={t('register.password')} newStyle={false}>
                                        <>
                                            <Input type='password' name='password' />
                                            <PasswordStrengthMeter password={passwordToValidate} />
                                        </>
                                    </InputField>
                                    <InputField fieldName={t('register.repeat_password')} newStyle={false}>
                                        <Input type='password' name='password_again'/>
                                    </InputField>

                                    {passwordToValidate && confirmPassword && passwordToValidate != confirmPassword && (
                                        <p className={classNames(styles.errorText)}>{t('register.password_not_match')}</p>
                                    )}
                                </>
                            )}
                        </div>)}
                    {currentWizardStep === 2 && (
                        <div>
                            <div className={styles.termsTextWrapper}>
                                <div className={styles.termsTextContent}>
                                    <p><b><span className={styles.listCount}>1.1</span> {t('terms_service.title_1.1')}</b></p>
                                    <p><span className={styles.listCount}>1.1.1</span> {t('terms_service.content_1.1.1')}</p>

                                    <p><b><span className={styles.listCount}>1.2.</span> {t('terms_service.title_1.2')}</b></p>
                                    <p><span className={styles.listCount}>1.2.1</span> {t('terms_service.content_1.2.1')}</p>

                                    <p><span className={styles.listCount}>1.2.2</span> {t('terms_service.content_1.2.2')}</p>
                                    <p><span className={styles.listCount}>a)</span> {t('terms_service.content_1.2.2a')}</p>
                                    <p><span className={styles.listCount}>b)</span> {t('terms_service.content_1.2.2b')}</p>
                                    <p><span className={styles.listCount}>c)</span> {t('terms_service.content_1.2.2c')}</p>

                                    <p><span className={styles.listCount}>1.2.3</span> {t('terms_service.content_1.2.3')}</p>

                                    <p><b><span className={styles.listCount}>1.3</span> {t('terms_service.title_1.3')}</b></p>
                                    <p><span className={styles.listCount}>1.3.1</span> {t('terms_service.content_1.3.1')}</p>
                                    <p><span className={styles.listCount}>a)</span> {t('terms_service.content_1.3.1a')}</p>
                                    <p><span className={styles.listCount}>b)</span> {t('terms_service.content_1.3.1b')}</p>

                                    <p><span className={styles.listCount}>1.3.2</span> {t('terms_service.content_1.3.2')}</p>

                                    <p><span className={styles.listCount}>1.3.3</span> {t('terms_service.content_1.3.3')}</p>

                                    <p><b><span className={styles.listCount}>1.4</span> {t('terms_service.title_1.4')}</b></p>
                                    <p><span className={styles.listCount}>1.4.1</span> {t('terms_service.content_1.4.1')}</p>

                                    <p><b><span className={styles.listCount}>1.5</span> {t('terms_service.title_1.5')}</b></p>
                                    <p><span className={styles.listCount}>1.5.1</span> {t('terms_service.content_1.5.1')}</p>

                                    <p><span className={styles.listCount}>1.5.2</span> {t('terms_service.content_1.5.2')}</p>

                                    <p><b><span className={styles.listCount}>1.6</span> {t('terms_service.title_1.6')}</b></p>
                                    <p><span className={styles.listCount}>1.6.1</span> {t('terms_service.content_1.6.1')}</p>
                                    <p><span className={styles.listCount}>•</span> {t('terms_service.content_1.6.1-1')}</p>
                                    <p><span className={styles.listCount}>•</span> {t('terms_service.content_1.6.1-2')}</p>
                                    <p><span className={styles.listCount}>•</span> {t('terms_service.content_1.6.1-3')}</p>
                                    <p><span className={styles.listCount}>•</span> {t('terms_service.content_1.6.1-4')}</p>

                                    <p><b>SERVICE LEVEL AGREEMENT</b></p>
                                    <p><b><span className={styles.listCount}>1.</span> {t('service_level.title_1')}</b></p>
                                    <p><span className={styles.listCount}>1.1</span> {t('service_level.content_1.1')}</p>

                                    <p><span className={styles.listCount}>1.2</span> {t('service_level.content_1.2')}</p>

                                    <p><span className={styles.listCount}>•</span> {t('service_level.content_1.2-1')}</p>
                                    <p><span className={styles.listCount}>•</span> {t('service_level.content_1.2-2')}</p>
                                    <p><span className={styles.listCount}>•</span> {t('service_level.content_1.2-3')}</p>
                                    <p><span className={styles.listCount}>•</span> {t('service_level.content_1.2-4')}</p>
                                    <p><span className={styles.listCount}>•</span> {t('service_level.content_1.2-5')}</p>

                                    <p><b><span className={styles.listCount}>2.</span>  {t('service_level.title_2')}</b></p>
                                    <p><span className={styles.listCount}>2.1</span> {t('service_level.content_2.1')}</p>

                                    <p><span className={styles.listCount}>2.2</span> {t('service_level.content_2.2')}</p>
                                    <p><span className={styles.listCount}>a)</span> {t('service_level.content_2.2a')}</p>
                                    <p><span className={styles.listCount}>b)</span> {t('service_level.content_2.2b')}</p>

                                    <p><span className={styles.listCount}>2.3</span> {t('service_level.content_2.3')}</p>
                                    <p><span className={styles.listCount}>a)</span> {t('service_level.content_2.3a')}</p>
                                    <p><span className={styles.listCount}>b)</span> {t('service_level.content_2.3b')}</p>
                                    <p><span className={styles.listCount}>c)</span> {t('service_level.content_2.3c')}</p>

                                    <p><span className={styles.listCount}>2.4</span> {t('service_level.content_2.4')}</p>

                                    <p><span className={styles.listCount}>2.5</span> {t('service_level.content_2.5')}</p>

                                    <p><span className={styles.listCount}>2.6</span> {t('service_level.content_2.6')}</p>
                                </div>
                            </div>


                            <div className={styles.termsCheckbox}>
                                <label>
                                    <input onChange={checkTermsAccepted} name='terms_accepted' type='checkbox' />
                                    <span>{t('register.agree_terms_service')}</span>
                                </label>
                            </div>
                        </div>)}

                    {zxcvbn(passwordToValidate).score > 2 && (<p className={classNames(styles.errorMessage, { [styles.hidden]: !errorMessage })}>{errorMessage}</p>)}
                    {passwordToValidate.length > 0 && zxcvbn(passwordToValidate).score <= 2 && (
                        <p className={styles.errorMessage}>{t('register.password_error_message')}</p>
                    )}

                    <div className={styles.submitButtonContainer}>
                        {currentWizardStep === 1 && (
                            <Button
                                type='button'
                                disabled={isNextStepDisabled || passwordToValidate != confirmPassword || (showPass && zxcvbn(passwordToValidate).score < 3)}
                                onClick={() => onWizardChange(2)}
                                large action
                            >
                                {t('register.continue_terms_service')}
                            </Button>)}


                        {currentWizardStep === 2 && (
                            <Button
                                type='submit'
                                disabled={!termsAccepted}
                                large action
                            >
                                {t('register.create_account')}
                            </Button>)}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export const RegisterForm = memo(RegisterFormComponent);
