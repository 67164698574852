import { HeaderInfo } from 'shared/components/Table/types';

export const employeesTableHeaders: HeaderInfo[] = [
  {
    fieldKey: 'full_name',
    displayName: 'tables.employees.full_name',
    sortable: true,
  },
  {
    fieldKey: 'status',
    displayName: 'tables.employees.status',
    sortable: true,
  },
  {
    fieldKey: 'role_id',
    displayName: 'tables.employees.role',
    sortable: true,
  },
];
