import React, { FC, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAccountPath } from 'redux/features/accountPath';
import classNames from 'classnames';
import styles from './Card.module.scss';

interface Props {
    item: { [key: string]: any }
}

const CardComponent: FC<Props> = ({ item }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handlePath = (item: any) => {
        if (!item.disabled) {
            dispatch(setAccountPath({ path: item.path, label: item.title }))
            history.push(item.path)
        }
    }

    return (
        <div className={classNames(styles.card, {
            [styles.disabled]: !!item.disabled
        })}
            onClick={() => handlePath(item)}
        >
            <img src={item.icon} alt="" />
            <p className={styles.cardTitle}>{t(item.title)}</p>
            <p className={styles.cardDescription}>{t(item.description)}</p>
        </div>
    );
};

export const Card = memo(CardComponent);
