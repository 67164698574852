import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RegisterForm } from './components/RegisterForm';
import Button from 'shared/components/Button/Button';
import { clearAuthData, getIsAuthDataPresent, navigateToLoginOnRegister } from 'helpers/auth';
import { ReactComponent as SuccessIcon } from 'static/icons/checkbox.svg';
import { RootState } from 'redux/store';

import styles from './RegisterUser.module.scss';
import classnames from 'classnames';
import { ReactComponent as InfoIcon } from 'static/icons/info.svg';
import { useTranslation } from 'react-i18next';

const RegisterUserComponent: FC = () => {
    const { t } = useTranslation();
    const [isUserAlreadyLoggedIn, setIsUserAlreadyLoggedIn] = useState(false);
    const isRegistrationComplete = useSelector<RootState, boolean>(
        (state) => state.registerUserPage.isRegistrationComplete
    );
    const [currentWizardStep, setCurrentWizardStep] = useState(1);
    const [isChrome, setIsChrome] = useState(true);

    useEffect(() => {
        const isAuthDataPresent = getIsAuthDataPresent();
        setIsUserAlreadyLoggedIn(isAuthDataPresent);
    }, [setIsUserAlreadyLoggedIn]);

    useEffect(() => {
        const isChromeBrowser = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        setIsChrome(isChromeBrowser);
    }, []);

    const redirectToSignIn = useCallback(() => {
        navigateToLoginOnRegister();
    }, []);

    const logout = useCallback(() => {
        clearAuthData();
        setIsUserAlreadyLoggedIn(false);
    }, [setIsUserAlreadyLoggedIn]);

    const handleWizardChange = (value: number) => {
        setCurrentWizardStep(value);
    };
    return (
        <div className={styles.root}>
            <div className={styles.logoContainer}>
                <img src='/img/xelacore-logo-full.svg' alt='logo' className={styles.logoImg} />
            </div>

            <div className={classnames(styles.card, { [styles.logout]: isUserAlreadyLoggedIn })}>
                {isUserAlreadyLoggedIn ? (
                    <div className={styles.logoutContainer}>
                        <h2 className={styles.heading}>{t('register.sign_in_message')}</h2>
                        <h4 className={styles.subHeading}>{t('register.sign_out_message')}</h4>
                        <Button onClick={logout} large action>{t('register.sign_out')}</Button>
                    </div>
                ) : (
                    <>
                        {!isRegistrationComplete ? (
                            <>
                                <div className={styles.wizardIndicatorWrapper}>
                                    <h6>{currentWizardStep} / 2</h6>
                                    <div className={styles.wizardIndicatorList}>
                                        <div className={classnames(styles.wizardIndicatorItem, styles.clickable, { [styles.active]: currentWizardStep > 0 })}
                                            onClick={() => setCurrentWizardStep(1)}
                                        />
                                        <div className={classnames(styles.wizardIndicatorItem, { [styles.active]: currentWizardStep > 1 })} />
                                    </div>
                                </div>

                                <h2 className={styles.heading}>{t('register.invite_message')}</h2>
                                {currentWizardStep === 1 ?
                                    (<h4 className={styles.subHeading}>{t('register.invite_description')}</h4>) :
                                    (<h4 className={styles.subHeading}>{t('register.accept_terms_service')}</h4>)
                                }
                                <div className={styles.formHolder}>
                                    <RegisterForm
                                        currentWizardStep={currentWizardStep}
                                        onWizardChange={handleWizardChange}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className={styles.logoutContainer}>
                                <SuccessIcon className={styles.successIcon} />
                                <h2 className={styles.heading}>{t('register.registration_complete')}</h2>
                                <Button onClick={redirectToSignIn} large action>{t('register.continue_sign_in')}</Button>
                            </div>
                        )
                        }
                        {!isChrome && (
                            <p className={styles.infoMessage}>
                                <InfoIcon />
                                <span>{t('register.tip_chrome')}</span>
                            </p>
                        )}
                    </>
                )}
            </div>
        </div >
    );
};

export const RegisterUser = memo(RegisterUserComponent);
