import React, { FC, memo } from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';
import { ReactComponent as OfficeAddressIcon } from 'static/icons/address-icon.svg';

import { FormikDropdown } from 'shared/components/FormikDropdown';
import { countries } from 'constants/countries';
import styles from '../../Company.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  values: { [key: string]: any }
}

const MainOfficeAddressComponent: FC<Props> = ({ values }) => {
  const { t } = useTranslation();
  const {address: {town, region, country, postcode}} = values;

  return (
    <>
      <div className={styles.mainTitle}>
        <OfficeAddressIcon className={styles.titleIcon} />
        <p className={styles.titleText}>{t('company_information.main_office_address.section')}</p>
      </div>

      <InputField fieldName={t('company_information.main_office_address.city')} value={town} disabled>
        <Input name="address.town" disabled formikField/>
      </InputField>

      <InputField fieldName={t('company_information.main_office_address.region')} value={region} disabled>
        <Input name="address.region" disabled formikField/>
      </InputField>

      <InputField fieldName={t('company_information.main_office_address.country')} value={countries.find(item => item.value === country)?.label} disabled>
        <FormikDropdown isSearchable={true} isDisabled={true} name="address.country" options={countries}/>
      </InputField>

      <InputField fieldName={t('company_information.main_office_address.postcode')} value={postcode} disabled>
        <Input name="address.postcode" disabled formikField/>
      </InputField>
    </>
  );
};

export const MainOfficeAddress = memo(MainOfficeAddressComponent);
