import { Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/store';
import { logout } from 'helpers/auth';
import { setCurrentOrgId } from 'redux/features/organisation/reducers';

import { ReactComponent as SearchIcon } from 'static/icons/search.svg';
import Organisation from 'interfaces/organisation';
import Input from 'shared/components/Input/Input';
import Button from 'shared/components/Button/Button';
import { clearError } from '../../pages/Team/redux';

import styles from './OrganisationSelect.module.scss';
import { fetchToken } from '../../api/user';
import cookie from 'js-cookie';
import { ORG_ID_COOKIE } from '../../constants/authConstants';
import { useTranslation } from 'react-i18next';

export default function OrganisationSelect(): React.ReactElement {
    const { t } = useTranslation();
    const organisations = useSelector<RootState, Organisation[]>((state) => state.organisation.organisations);
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');

    const selectOrganisation = (orgId: string) => {
        dispatch(setCurrentOrgId(orgId));
        setTimeout(() => {
            fetchToken().then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 33)
            });
        }, 33)

    };
    const currentOrganisationId = cookie.get(ORG_ID_COOKIE);
    const licensors = organisations.filter(({ name, type }) => name.toLowerCase().includes(searchText.toLowerCase()) || type?.includes(searchText.toLowerCase()));

    if (organisations.length === 1 && (currentOrganisationId !== organisations[0].organisation_id)) {
        selectOrganisation(organisations[0].organisation_id)
    }

    return (
        <div className={styles.root}>
            <div className={styles.logoHolder}>
                <img className={styles.logoImg} src='/img/xelacore-logo.svg' alt='logo' />
            </div>
            <h1 className={styles.heading}>{t('organisation.account_selection')}</h1>
            <h3 className={styles.subHeading}>{t('organisation.account_selection_description')}</h3>
            <div className={styles.searchInputContainer}>
                <Formik
                    initialValues={{ searchText }}
                    onSubmit={() => {
                        () => dispatch(clearError())
                    }}
                >
                    <Input
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className={styles.searchInput}
                        autoFocus={true}
                        placeholder={t('common.search')}
                        icon={<SearchIcon className={styles.searchIcon} />}
                    />
                </Formik>
            </div>
            <div className={styles.orgList}>
                {licensors.map(({ organisation_id, name, type }) => (
                    <div key={organisation_id} onClick={() => selectOrganisation(organisation_id)}
                        className={styles.orgCard}>
                        <p className={styles.orgName}>{name}</p>
                        <p className={styles.orgType}>{type}</p>
                    </div>
                ))}
            </div>
            <Button
                large text
                onClick={logout}
                className={styles.logoutButton}
            >
                {t('menu.log_out')}
            </Button>
        </div>
    );
}
