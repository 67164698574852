import React, { FC, memo, useEffect, useState } from 'react';
import md5 from 'md5';
import styles from './ProfilePicture.module.scss';

interface Props {
  src: string | null;
  email: string;
  uploadedImage: File | null;
  setUploadedImage: (image: File) => void;
  initials: string;
}

const ProfilePicture: FC<Props> = ({ src, email, uploadedImage, initials }) => {
  const [uploadedImageSrc, setUploadedImageSrc] = useState<string | null | undefined>(null);

  useEffect(() => {
    if (uploadedImage) {
      const reader = new FileReader();
      reader.readAsDataURL(uploadedImage);
      reader.onload = () => setUploadedImageSrc(reader.result as string);
    } else setUploadedImageSrc(null)
  }, [uploadedImage]);

  const imgSrc = uploadedImageSrc || src || `//www.gravatar.com/avatar/${md5(email)}?s=50&d=identicon`;

  return (
    <div className={styles.root}>
      {imgSrc ? (
        <img src={imgSrc} className={styles.userAvatar} />
      ) : (
        <div className={styles.userNoAvatar}>{initials}</div>
      )}
    </div>
  );
};

export default memo(ProfilePicture);
