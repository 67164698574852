import React, { FC, memo } from 'react';

import InputField from '../../../../shared/components/InputField/InputField';
import Input from '../../../../shared/components/Input/Input';
import styles from '../../Security.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    values: { [key: string]: any }
}

const PasswordInfo: FC<Props> = ({ values }) => {
    const { t } = useTranslation();
    const { current_password, new_password, confirm_password } = values;

    return (
        <div className={styles.root}>
            <InputField fieldName={t('security.current_password')} value={current_password}>
                <Input name="current_password" formikField />
            </InputField>

            <InputField fieldName={t('security.new_password')} value={new_password}>
                <Input name="new_password" formikField />
            </InputField>

            <InputField fieldName={t('security.confirm_password')} value={confirm_password}>
                <Input name="confirm_password" formikField />
            </InputField>
        </div>
    );
};

export default memo(PasswordInfo);
