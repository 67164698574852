import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import './i18n'

const LoadingFallback = () => <div>Loading...</div>;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <Suspense fallback={<LoadingFallback />}>
      <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
