import React, { FC, memo, useEffect, useState } from 'react';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as AngleIcon } from 'static/icons/angle-right.svg';
import { setAccountPath } from 'redux/features/accountPath';
import classNames from 'classnames';
import styles from './NavLinks.module.scss';


const NavLinks: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  useEffect(() => history.listen(({ pathname }) => setCurrentPath(pathname)), [history]);

  const path = useSelector<RootState, string>((state) => state.accountPath.path);
  const pathTitle = useSelector<RootState, string>((state) => state.accountPath.label);

  return (
    <div className={styles.root}>
      {path !== '/' && (
        <div className={styles.navigationContainer}>
          <Link
            className={classNames(styles.navLink, {
              [styles.active]: currentPath === '/'
            })}
            onClick={() => dispatch(setAccountPath({ path: '/', label: 'navigation.title.my_account' }))}
            to={'/'}
          >
            {t('navigation.title.my_account')}
          </Link>

          <AngleIcon />
          
          <div
            className={classNames(styles.navLink, {
              [styles.active]: currentPath === path
            })}
          >
            {t(pathTitle)}
          </div>
        </div>
      )}
      <p className={styles.pageTitle}>{t(pathTitle)}</p>
    </div>
  );
};

export default memo(NavLinks);
