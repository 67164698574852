import React, { FC, memo, useEffect } from 'react';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileInfo } from 'api/files/types';
import config from '../../config/env-config';
import { getFilesList } from './redux';
import { setAccountPath } from 'redux/features/accountPath';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import { FileBlock } from './components/FileBlock';
import NavLinks from 'components/NavLinks/NavLinks';
import styles from './Terms.module.scss';

const TermsComponent: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filesList = useSelector<RootState, FileInfo[]>((state) => state.termsPage.files);

  useEffect(() => {
    return () => {
      dispatch(setAccountPath({ path: '/', label: 'navigation.title.my_account' }))
    }
  }, [])

  useEffect(() => {
    dispatch(getFilesList());
  }, []);

  return (
    <div className={styles.root}>
      <PageBlock>
        <div className={styles.content}>
          <NavLinks />
          <div className={styles.fileBlocksContainer}>
            <a className={styles.label} href={config.register_url} target='_blank'>
              {t('terms.title')}
            </a>
            {filesList.map((fileInfo) => (
              <FileBlock key={fileInfo.md5} fileInfo={fileInfo} />
            ))}
          </div>
        </div>
      </PageBlock>
    </div>
  );
};

export const Terms = memo(TermsComponent);
