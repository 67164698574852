import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './MyProfile.module.scss';
import { User } from 'interfaces/user';
import { getUser, updateUser } from 'redux/features/user';
import { RootState } from 'redux/store';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import MainInfo from './components/MainInfo/MainInfo';
import ProfileHeader from './components/ProfileHeader/ProfileHeader';
import { Form, Formik } from 'formik';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import NavLinks from 'components/NavLinks/NavLinks';
import { setAccountPath } from 'redux/features/accountPath';

const MyProfileComponent: FC = () => {
    const dispatch = useDispatch();
    const user = useSelector<RootState, User | null>((state) => state.user.user);
    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(setAccountPath({ path: '/', label: 'navigation.title.my_account' }))
        }
    }, [])

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        setIsSubmitting(false);
        // setUploadedImage(null);
    }, [user]);

    return (
        <div className={styles.root}>
            <PageBlock>
                <NavLinks />
                {user && (
                    <Formik
                        enableReinitialize
                        initialValues={user}
                        onSubmit={(values) => {
                            // values.information.title = values.information.title || '';
                            // values.information.title = values.information.last_name || '';
                            dispatch(updateUser({ user: values, image: uploadedImage }));
                        }}
                    >
                        {({ values }) => (
                            <Form className={styles.form}>
                                <ProfileHeader
                                    uploadedImage={uploadedImage}
                                    setUploadedImage={setUploadedImage}
                                    user={user}
                                />

                                <MainInfo values={values} />
                            </Form>
                        )}
                    </Formik>
                )}
                {(isSubmitting || !user) && <LoadingOverlay />}
            </PageBlock>
        </div>
    );
};

export const MyProfile = memo(MyProfileComponent);
