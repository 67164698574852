import React, { FC, memo } from 'react';
import styles from './RegistrationProject.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    aliases: string[];
    gcpList: string[];
    organisationName: string;
    gtinWaiver: boolean;
}

const RegistrationProjectComponent: FC<Props> = ({ aliases, gcpList, gtinWaiver }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <h2 className={styles.title}>{t('settings.doing_business_as')}</h2>
            <div className={styles.row}>
                {aliases.map((alias, aliasIndex) => (
                    <span key={aliasIndex}>{alias}</span>
                ))}
            </div>
            <div className={styles.divider} />

            <h2 className={styles.title}>{t('settings.gcp')}</h2>
            <div className={styles.row}>
                {gcpList.map((gcp, gcpIndex) => (
                    <span key={gcpIndex}>{gcp}</span>
                ))}
            </div>
            <div className={styles.divider} />

            {/* <h2 className={styles.title}>GTIN Settings</h2> */}
            <h3 className={styles.title}>{t('settings.gtin')}</h3>
            <div className={styles.row}>
                <span>{gtinWaiver ? t('settings.yes') : t('settings.no')} </span>
            </div>
            <div className={styles.divider} />
        </div>
    );
};

export const RegistrationProject = memo(RegistrationProjectComponent);
