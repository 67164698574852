import { Formik } from 'formik';
import React, { Ref, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Checkbox from 'shared/components/Checkbox/Checkbox';
import Input from 'shared/components/Input/Input';
import { clearError } from '../../../../../pages/Team/redux';
import { FilterType } from '../../enums';
import { FilterInfo } from '../../types';

import styles from './ColumnFilter.module.scss';

interface Props {
  filterInfo: FilterInfo;
  filterValue: string | string[];
  position: { top: number; left: number };
  onChange: (value: string | string[]) => void;
  forwardedRef?: Ref<HTMLDivElement>;
}

function ColumnFilter({
  forwardedRef,
  position = { top: 0, left: 0 },
  filterInfo = {
    type: FilterType.text,
  },
  filterValue,
  onChange,
}: Props): React.ReactElement {
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleCheckboxChange = useCallback(
    (option: string, isChecked: boolean) =>
      onChange(
        isChecked
          ? [...(filterValue as string[]), option]
          : (filterValue as string[]).filter((v: string) => v !== option)
      ),
    [filterValue]
  );

  useEffect(() => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    input.focus();
    input.setSelectionRange(0, input.value.length);
  }, [inputRef]);

  return (
    <div ref={rootRef} className={styles.root} style={{ top: position.top, left: position.left }}>
      <div ref={forwardedRef} className={styles.panelHolder}>
        {!filterInfo.options && (
          <Formik
            initialValues={{ filterValue }}
            onSubmit={() => {
              () => dispatch(clearError())
            }}
          >
            <Input
              ref={inputRef}
              placeholder='Type here...'
              onChange={(e) => onChange(e.target.value)}
              value={(filterValue as string) || ''}
            />
          </Formik>
        )}
        {filterInfo.options && (
          <div className={styles.listContainer}>
            {filterInfo.options.map(({ value, label }) => (
              <Checkbox
                key={value}
                onChange={(e) => handleCheckboxChange(value.toString(), e.target.checked)}
                checked={(filterValue as string[]).some((v) => v === value)}
              >
                {label}
              </Checkbox>
            ))}
            <span className={styles.resetFiltersButton} onClick={() => onChange([])}>
              Reset
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  return <ColumnFilter {...props} forwardedRef={ref} />;
});
