import React, { FC, memo } from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';
import { ReactComponent as BillingIcon } from 'static/icons/billing-icon.svg';
import styles from '../../Company.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    values: { [key: string]: any }
}

const BillingComponent: FC<Props> = ({ values }) => {
    const { t } = useTranslation();
    const { street, town, region, country, postcode } = values.billing_address;

    return (
        <>
            <div className={styles.mainTitle}>
                <BillingIcon className={styles.titleIcon} />
                <p className={styles.titleText}>{t('company_information.billing.section')}</p>
            </div>

            <InputField fieldName={t('company_information.billing.billing_street_address')} value={street} disabled>
                <Input disabled name="street" formikField />
            </InputField>

            <InputField fieldName={t('company_information.billing.billing_city')} value={town} disabled>
                <Input disabled name="town" formikField />
            </InputField>

            <InputField fieldName={t('company_information.billing.billing_region')} value={region} disabled>
                <Input disabled name="region" formikField />
            </InputField>

            <InputField fieldName={t('company_information.billing.billing_country')} value={country} disabled>
                <Input disabled name="country" formikField />
            </InputField>

            <InputField fieldName={t('company_information.billing.billing_postcode')} value={postcode} disabled>
                <Input disabled name="postcode" formikField />
            </InputField>
        </>
    );
};

export const Billing = memo(BillingComponent);
