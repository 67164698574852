import { FC, memo, useCallback } from 'react';
import zxcvbn from 'zxcvbn';
import styles from './PasswordStrengthMeter.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    password: string;
}

const PasswordStrengthMeter: FC<Props> = ({ password }) => {
    const { t } = useTranslation();
    const testedResult = zxcvbn(password);

    const createPasswordLabel = useCallback((result) => {
        switch (result.score) {
            case 0:
                return `${result.feedback.warning ? result.feedback.warning + '.' : ''} ${result.feedback.suggestions && result.feedback.suggestions.length ? result.feedback.suggestions[0] : ''}`;
            case 1:
                return `${t('register.password_weak')} ${result.feedback.warning ? '- ' + result.feedback.warning + '.' : ''}`;
            case 2:
                return `${t('register.password_fair')} ${result.feedback.warning ? '- ' + result.feedback.warning + '.' : ''}`;
            case 3:
                return  t('register.password_good');
            case 4:
                return t('register.password_strong');
            default:
                return t('register.password_rule');
        }
    }, [])

    const createPasswordClass = useCallback((result) => {
        switch (result.score) {
            case 0:
                return 'Weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return 'Weak';
        }
    }, [])

    return (
        <>
            <div className={styles.passwordStrengthMeter}>
                <progress
                    className={`${styles['passwordStrengthMeterProgress']} ${styles[`strength${createPasswordClass(testedResult)}`]}`}
                    value={testedResult.score}
                    max='4'
                />
                
                <label className={`${styles['passwordStrengthMeterLabel']} ${styles[`strength${password ? createPasswordClass(testedResult) : 'Default'}`]}`}>
                    {password && (
                        <>
                            {createPasswordLabel(testedResult)}
                        </>
                    )}

                    {!password && (
                        <>{t('register.password_characters')}</>
                    )}
                </label>
            </div>
        </>
    )
};

export default memo(PasswordStrengthMeter);
