import React, { FC, memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import Button from 'shared/components/Button/Button';
import Input from 'shared/components/Input/Input';
import styles from './InviteUser.module.scss';
import { Formik } from 'formik';
import { ReactComponent as CloseIcon } from 'static/icons/cross-small.svg';
import { useTranslation } from 'react-i18next';

interface Props {
    onEmailSubmit: (email: string, firstName: string, lastName: string) => void;
    onValueChange: () => void;
    errorMessage?: string | null;
    setIsInviteUiShown: (isInviteUiShown: boolean) => void;
}

const InviteUserComponent: FC<Props> = ({ onEmailSubmit, errorMessage, onValueChange, setIsInviteUiShown }) => {
    const { t } = useTranslation();

    const [inviteEmail, setInviteEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const onFormSubmit = useCallback(
        (e) => {
            e.preventDefault();
            onEmailSubmit(inviteEmail, firstName, lastName);
            setIsInviteUiShown(false)
        },
        [onEmailSubmit, inviteEmail, firstName, lastName]
    );

    const disabledForm = () => {
        return inviteEmail.length === 0 || firstName.length === 0 || lastName.length === 0;
    }

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <p className={styles.title}>{t('modals.invite_user.title')}</p>
                    <button onClick={() => setIsInviteUiShown(false)}>
                        <CloseIcon className={styles.icon} />
                    </button>
                </div>
                <Formik
                    initialValues={{ firstName, lastName, inviteEmail }}
                    onSubmit={onFormSubmit}
                >
                    <form onSubmit={onFormSubmit}
                        className={styles.inviteFormContainer}>

                        <div className={styles.nameBox}>
                            <div className={classNames(styles.inputBox, styles.required)}>
                                <Input
                                    type="text"
                                    required
                                    placeholder={t('modals.invite_user.placeholders.first_name')}
                                    value={firstName}
                                    onChange={(e) => {
                                        onValueChange();
                                        setFirstName(e.target.value);
                                    }}
                                    error={errorMessage}
                                />
                            </div>

                            <div className={classNames(styles.inputBox, styles.required)}>
                                <Input
                                    type="text"
                                    required
                                    placeholder={t('modals.invite_user.placeholders.last_name')}
                                    value={lastName}
                                    onChange={(e) => {
                                        onValueChange();
                                        setLastName(e.target.value);
                                    }}
                                    error={errorMessage}
                                />
                            </div>
                        </div>

                        <div className={classNames(styles.inputBox, styles.required)}>
                            <Input
                                type="email"
                                required
                                placeholder={t('modals.invite_user.placeholders.user_email')}
                                value={inviteEmail}
                                onChange={(e) => {
                                    onValueChange();
                                    setInviteEmail(e.target.value);
                                }}
                                error={errorMessage}
                            />
                        </div>

                        <div className={styles.inviteFormControlsContainer}>
                            <Button danger2 onClick={() => setIsInviteUiShown(false)}>{t('modals.invite_user.buttons.cancel')}</Button>
                            <Button action disabled={disabledForm()} type="submit">{t('modals.invite_user.buttons.invite')}</Button>
                        </div>
                    </form>
                </Formik>
            </div>
        </div>
    );
};

export const InviteUser = memo(InviteUserComponent);
