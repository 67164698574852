import React, { FC, memo } from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';
import { ReactComponent as BusinessInfoIcon } from 'static/icons/business-icon.svg';
import styles from '../../Company.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  values: { [key: string]: any }
}

const BusinessInfoComponent: FC<Props> = ({ values }) => {
  const { t } = useTranslation();
  const {legal_company_name, company_registration_number, business_unit, company_number, uk_vat, phone} = values;

  return (
    <>
      <div className={styles.mainTitle}>
        <BusinessInfoIcon className={styles.titleIcon} />
        <p className={styles.titleText}>{t('company_information.business_info.section')}</p>
      </div>

      <InputField fieldName={t('company_information.business_info.legal_company_name')} value={legal_company_name} disabled>
        <Input name="legal_company_name" disabled formikField />
      </InputField>

      <InputField fieldName={t('company_information.business_info.company_registration_number')} value={company_registration_number} disabled>
        <Input name="company_registration_number" disabled formikField />
      </InputField>

      <InputField fieldName={t('company_information.business_info.business_unit')} value={business_unit} disabled>
        <Input name="business_unit" disabled formikField />
      </InputField>

      <InputField fieldName={t('company_information.business_info.company_number')} value={company_number} disabled>
        <Input name="company_number" disabled formikField />
      </InputField>

      <InputField fieldName={t('company_information.business_info.uk_vat')} value={uk_vat} disabled>
        <Input name="uk_vat" disabled formikField />
      </InputField>

      <InputField fieldName={t('company_information.business_info.phone')} value={phone} disabled>
        <Input required={true} disabled name="phone" formikField />
      </InputField>
    </>
  );
};

export const BusinessInfo = memo(BusinessInfoComponent);
