import { createSlice } from '@reduxjs/toolkit';
import { navigationCards } from 'constants/navigationConstants';

const initialPath = typeof window !== undefined ? window?.location?.pathname : '/';
const initialPathLabel = navigationCards?.find(item => item.path === initialPath)?.title || 'navigation.title.my_account'

const initialState = {
  path: initialPath,
  label: initialPathLabel
}

export const accountPathSlice = createSlice({
  name: 'accountPath',
  initialState,
  reducers: {
    setAccountPath: (state, action) => {
      state.path = action.payload.path;
      state.label = action.payload.label;
    }
  },
});

export const { setAccountPath } = accountPathSlice.actions;

export default accountPathSlice.reducer;
