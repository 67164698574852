import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Employee } from 'interfaces/employee';

import styles from './Team.module.scss';
import md5 from 'md5';
import { useTranslation } from 'react-i18next';

const DEFAULT_ROLE_ID = '88c0a3ca-cbce-4b27-b390-f6a001ca129f';

export const getTableRows = (employees: Employee[]): ReactNode[][] => {
    const { t } = useTranslation();
    return employees.filter(employee => !!employee.email && !employee.email?.endsWith('@fabacus.com')).map(
        (employee) => {
            const role = employee.role_id === DEFAULT_ROLE_ID ? t('tables.employees.role_member') : t('tables.employees.role_user');
            const status = employee.user_id ? t('tables.employees.status_active') : t('tables.employees.status_pending');
            return getTableRow(employee, role, status)
        });
}


export const getTableRow = ({
    full_name,
    email,
    job_title,
    profile_image,
    user_id
}: Employee, role: string, status: string): ReactNode[] => [
        <div className={styles.userBlock}>
            {
                <img
                    className={styles.profileImage}
                    src={profile_image || ''}
                    onError={(e) => ((e.target as HTMLImageElement).src = `//www.gravatar.com/avatar/${md5((email || 'sample@mail.com').toString())}?s=50&d=identicon`)}
                />
            }
            <div className={classNames({ [styles.userTextContainer]: job_title })}>
                <h2 className={styles.fullName}>{full_name}</h2>
                {/* {job_title && <p className={styles.title}>{job_title}</p>} */}
                <p className={styles.email}>{email}</p>
            </div>
        </div>,
        <p className={classNames(styles.status, user_id ? styles.active : styles.pending)}>{status}</p>,
        <p className={styles.modules}>{role}</p>
    ]
