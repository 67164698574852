import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './ProfileHeader.module.scss';
import { User } from 'interfaces/user';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import { updateUser } from 'redux/features/user';
import { AppDispatch } from 'redux/store';
import { useTranslation } from 'react-i18next';

interface Props {
  user: User;
  uploadedImage: File | null;
  setUploadedImage: (image: File | null) => void;
}

const ProfileHeader: FC<Props> = ({ user, uploadedImage, setUploadedImage }) => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();
  const [editField, setEditField] = useState(false);

  const inputFileButtonRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    const buttonElement = inputFileButtonRef?.current;
    buttonElement?.click();
    setEditField(true)
  };

  const onFileSelect = useCallback((e) => {
    const file = e.target.files[0];
    setUploadedImage(file);
  }, []);

  const handleSubmit = (imageData: File | null) => {
    dispatch(updateUser({ user, image: imageData }))
      .then((res) => {
        if (res) {
          setEditField(false);
        }
      })
  }

  return (
    <div className={styles.root}>
      <ProfilePicture
        uploadedImage={uploadedImage}
        setUploadedImage={setUploadedImage}
        src={user.information.profile_image}
        email={user.email}
        initials={`${user.information.first_name[0]}${user.information.last_name[0]}`}
      />

      <div className={styles.actions}>
        <button
          type="button"
          className={styles.changeAvatar}
          onClick={handleClick}
        >
          <span>{t('personal_information.change_avatar')}</span>
        </button>
        <input
          ref={inputFileButtonRef}
          onChange={onFileSelect}
          type="file"
          accept="image/png, image/jpeg"
          className={styles.hidden}
        />

        {editField &&
          <div className={styles.fieldAction}>
            <button
              type="button"
              className={styles.actionButton}
              onClick={() => handleSubmit(uploadedImage)}
            >
              {t('common.update')}
            </button>
            <button
              type="button"
              className={styles.actionButton}
              onClick={() => {
                setUploadedImage(null);
                 setEditField(false)}
                }
            >
               {t('common.cancel')}
            </button>
          </div>
        }
        
        {/* {!!user.information?.profile_image &&
          <button
            type="button"
            className={styles.changeAvatarLink}
            onClick={() => handleSubmit(null)}
          >
            <span>{t('profile_information.remove_avatar')}</span>
          </button>
        } */}
      </div>
    </div>
  );
};

export default memo(ProfileHeader);
