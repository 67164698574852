import {Formik} from 'formik';
import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';

import { ReactComponent as SearchIcon } from 'static/icons/search.svg';
import { ReactComponent as CrossIcon } from 'static/icons/cross-small.svg';
import {clearError} from '../../../Team/redux';
import { ListViewEntry } from './types';
import styles from './ListView.module.scss';
import Input from 'shared/components/Input/Input';

interface Props {
  entries: ListViewEntry[];
  title: string;
  filterValue: string;
  selectedEntryId?: string | null;
  setFilterValue: (value: string) => void;
  onEntryClick?: (id: string) => void;
}

const ListViewComponent: FC<Props> = ({
  entries,
  title,
  selectedEntryId,
  filterValue,
  setFilterValue,
  onEntryClick = noop,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const openFilter = useCallback(() => {
    setIsFilterOpen(true);
  }, [setIsFilterOpen]);

  useEffect(() => {
    if (isFilterOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFilterOpen]);

  const closeFilter = useCallback(() => {
    setFilterValue('');
    setIsFilterOpen(false);
  }, [setIsFilterOpen]);

  return (
    <div className={styles.root}>
      <Formik
          initialValues={{filterValue}}
          onSubmit={() => {
            () => dispatch(clearError())
          }}
      >
      <div className={styles.headerBlock}>
        <p className={styles.title}>
          {title}
          <SearchIcon onClick={openFilter} className={styles.searchIcon}></SearchIcon>
        </p>
        {isFilterOpen && (
          <div className={styles.searchHolder}>
            <CrossIcon onClick={closeFilter} className={styles.crossIcon}></CrossIcon>
            <Input ref={inputRef} onChange={(e) => setFilterValue(e.target.value)} value={filterValue}></Input>
          </div>
        )}
      </div>
      </Formik>
      <div className={styles.listHolder}>
        {entries.map(({ name, id }) => (
          <div
            key={id}
            className={classNames(styles.listItem, { [styles.selected]: selectedEntryId === id })}
            onClick={() => onEntryClick(id)}
          >
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

export const ListView = memo(ListViewComponent);
