import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {logout} from 'helpers/auth';
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentOrgId} from 'redux/features/organisation/reducers';
import {ErrorView} from '../ErrorView/ErrorView';
import {ToastContainer} from 'react-toastify';
import {DropdownMenu} from 'shared/components/DropdownMenu';
import {RootState} from 'redux/store';
import {User} from 'interfaces/user';
import {getUser} from 'redux/features/user';
import styles from './Layout.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import {userInfoDataService} from '../../shared/services/user-info-service';
import { setAccountPath } from 'redux/features/accountPath';
import { useTranslation } from 'react-i18next';

interface Props {
    children: React.ReactNode;
    hideMenu: boolean;
    hideCover: boolean;
}

export default function Layout({children, hideMenu}: Props): React.ReactElement {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector<RootState, User | null>((state) => state.user.user);

    userInfoDataService.getData().subscribe(newProfileImage => {
        if(newProfileImage !== user?.information?.profile_image){
            dispatch(getUser());
        }
    })
    useEffect(() => {
        dispatch(getUser());
    }, []);

    return (
        <ErrorView>
            <div className={styles.root}>
                <div className={styles.header}>
                    <div className={styles.headerContainer}>
                        <div className={styles.logoContainer}>
                            <img src="/img/xelacore-logo-full.svg" alt="logo" className={styles.logoImg}/>
                        </div>

                        <div className={styles.controlsBlock}>
                            {user && (
                                <>
                                    <DropdownMenu
                                        title={
                                            `${user.information.first_name ? user.information.first_name : ''}
                                             ${user.information.last_name ? user.information.last_name : ''}`}

                                        userdata={{
                                            imagepresence: true,
                                            image: user.information.profile_image || '',
                                            email: user.email || ''
                                        }}
                                        options={[
                                            {
                                                label: t('menu.my_account'),
                                                onClick: () => {
                                                    history.push('/');
                                                    dispatch(setAccountPath({ path: '/', label: 'navigation.title.my_account' }))
                                                },
                                            },
                                            {
                                                label: t('menu.change_organisation'),
                                                onClick: () => {
                                                    dispatch(setCurrentOrgId(null));
                                                },
                                            },
                                            {
                                                label: t('menu.sign_out'),
                                                onClick: logout,
                                            },
                                        ]}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.contentContainer}>
                        <div className={hideMenu ? styles.mainBlockNoNav : styles.mainBlock}>
                            <div className={styles.contentHolder}>{children}</div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </ErrorView>
    );
}
