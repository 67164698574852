import {createAsyncThunk} from '@reduxjs/toolkit';
import {
    fetchOrganisations,
    OrganisationUpdateEntity,
    patchOrganisation,
    fetchAllEmployees
} from 'api/organisation';
import {RootState} from 'redux/store';
import {toast} from 'react-toastify';

export const getOrganisations = createAsyncThunk('orgnisation/getOrganisations', async () => {
    const response = await fetchOrganisations();
    return response.data;
});

export const getEmployees = createAsyncThunk('orgnisation/getEmployees', async (_, {getState}) => {
    const {
        organisation: {currentOrgId},
    } = getState() as RootState;
    return currentOrgId ? await fetchAllEmployees(currentOrgId) : [];
});

export const updateOrganisation = createAsyncThunk(
    'orgnisation/updateOrganisation',
    async (organisation: OrganisationUpdateEntity) => {
        try {
            const response = await patchOrganisation(organisation);
            toast.success('Company Information Updated Successfully!', {
                position: toast.POSITION.TOP_RIGHT
            });
            return response.data.details;
        } catch (e) {
            toast.error('Unexpected error, please try again or contact support.', {
                position: toast.POSITION.TOP_RIGHT
            });
            return organisation
        }
    }
);
