import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TableBody.module.scss';

interface Props {
  rows: React.ReactNode[][];
}

export default function TableBody({ rows }: Props): React.ReactElement {
  const { t } = useTranslation();

  return (
    <tbody className={styles.root}>
      {rows?.length ? (
        <>
          {rows.map((cols, i) => (
            <tr className={styles.tableRow} key={i}>
              {cols.map((col, i) => (
                <td key={i} className={styles.tableCell}>
                  {col}
                </td>
              ))}
            </tr>
          ))}
        </>
      ) : (
        <tr className={styles.emptyTableRow}>
          <td>{t('tables.no_entries')}</td>
        </tr>
      )}
    </tbody>
  );
}
