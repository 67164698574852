import React, { FC, memo, ReactChild } from 'react';
import styles from './InputField.module.scss';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  children?: ReactChild;
  fieldName: string;
  value?: string;
  disabled?: boolean;
  editField?: any;
  newStyle?: boolean;
  submitAction?: () => void;
  setEditField?: any;
}

const InputField: FC<Props> = ({ children, fieldName, value, disabled, editField, newStyle = true, setEditField, submitAction }) => {
  const { t } = useTranslation();
  const path = useSelector<RootState, string>((state) => state.accountPath.path);

  return (
    <div className={classNames(styles.root, {
      [styles.border]: newStyle,
      [styles.field]: path === '/security' || editField
    })}>
      <div className={styles.fieldRow}>
        <p className={styles.fieldLabel}>{fieldName}</p>
        {(editField || !newStyle) ? (
          <>
            {children}
          </>
        ) : (
          <p className={classNames(styles.filedValue, {
            [styles.notProvided]: !value
          })}>{value ? value : t('common.not_provided')}</p>
        )}
      </div>
  
      {!disabled && !!newStyle && (
        <div className={styles.fieldAction}>
          {!editField ? (
            <button
              type="button"
              className={styles.actionButton}
              onClick={setEditField && setEditField}
            >
              {value ? t('common.edit') : t('common.add')}
            </button>
          ) : (
            <>
              <button
                type="button"
                className={styles.actionButton}
                onClick={submitAction}
              >
                {t('common.update')}
              </button>
              <button
                type="button"
                className={styles.actionButton}
                onClick={setEditField && setEditField}
              >
                {t('common.cancel')}
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(InputField);
